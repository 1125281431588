<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large>
          mdi-handshake
        </v-icon>
        รายการเข้าพบลูกค้า
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customerMeetings"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomerMeetings"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.address`]="{ item }">
            <span>
              {{
                `${item.address} ${
                  item.subdistrict.district.province.id == 1 ? "" : "ต."
                }${item.subdistrict.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "อ."
                }${item.subdistrict.district.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "จังหวัด"
                }${item.subdistrict.district.province.name_in_thai} ${
                  item.subdistrict.zip_code
                }`
              }}
            </span>
          </template> -->
          <template v-slot:[`item.date`]="{ item }">
            <span>
              {{ formatDate(item.date) }}
            </span>
          </template>
          <template v-slot:[`item.customerMeetingPhotos.length`]="{ item }">
            <span>
              <v-chip small :color="getColor(item.customerMeetingPhotos.length)" dark>
                {{ new Intl.NumberFormat("th").format(item.customerMeetingPhotos.length) }}
              </v-chip>
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer-meeting/' + item.id + '/show'"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
              small
              color="primary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/edit'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn> -->
            <!-- <v-btn
            small
            color="primary"
            class="ma-2"
            outlined
            :to="'/customer/' + item.id + '/delete'"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            Delete
          </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

moment.locale("th");

export default {
  name: "CustomerMeetingList",
  props: {},
  data() {
    return {
      search: "",
      totalCustomerMeetings: 0,
      options: {},
      customerMeetings: [],
      loading: false,
      headers: [
        {
          text: "ลูกค้า",
          value: "customer.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ศูนย์บริการลูกค้า",
          value: "customer.officeCentre.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนขายและบริการลูกค้า",
          value: "customer.officeCentre.officeSector.name",
          sortable: false,
          align: "center",
        },
        {
          text: "วันที่เข้าพบ",
          value: "date",
          sortable: false,
          align: "center",
        },
        {
          text: "รูปภาพ",
          value: "customerMeetingPhotos.length",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer-meeting", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerMeetings = response.data.data;
          this.totalCustomerMeetings = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getColor(number) {
      if (number === 0) return "grey";
      else return "green";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
